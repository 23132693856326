import React, { useReducer, useState, useEffect, useMemo } from 'react'

import {
  selectFamilyMembersMappedToDropdown,
  changesInStatesAndObs,
  selectChangesSuccess,
} from 'app/slices/company'

import { getStates, selectStates } from 'app/slices/stateSlice'

import * as C from 'app/components'
import * as S from './styles'

import holderScreenReducer, { HolderScreenActions } from './State'
import { FamilyGroupDetail } from 'domain/entities/familyGroupDetail'
import { useSelector, useDispatch } from 'react-redux'

import StatesModal from './StatesModal'
import { pymeStatesLabel } from 'app/constants/states'
import SavedChangesMsg from 'app/components/SavedChangesMsg'
import ObservationsAccordion from '../../components/ui/ObservationsCard'
import History from '../../components/ui/HistoryCard'

interface HolderDataScreenProps {
  onPressBack: () => void
  provincies: any
  familyGroupDetail: FamilyGroupDetail | null
  params: any
}

const HolderDataScreen = (props: HolderDataScreenProps) => {
  const dispatch = useDispatch()
  const holderData = props.familyGroupDetail?.socios[0]
  const [state, localDispatch] = useReducer(holderScreenReducer, {
    dropDownValue: { label: '', value: '' },
    familyMember: props.familyGroupDetail?.socios[0],
    familyMemberMappedKeyValueData: null,
    provincies: props.provincies,
  })
  const [modal, setModal] = useState(true)
  const familyMemberOptions: C.ISelectFormat[] = useSelector(
    selectFamilyMembersMappedToDropdown
  )

  const memberStates = useSelector(selectStates)

  const addressData = useMemo(() => {
    if (holderData) {
      return [
        { calle: holderData.direccion?.calle },
        { altura: holderData.direccion?.altura },
        { piso: holderData.direccion?.piso },
        { depto: holderData.direccion?.depto },
        {
          provincia: state.provincies?.find(
            p => p.id === holderData.direccion.idProvincia
          )?.nombre,
        },
        {
          localidad: state.provincies?.find(
            p => p.id === holderData.direccion.idProvincia
          )?.localidades.length
            ? holderData.direccion.localidad
            : '-',
        },
        { codpostal: holderData.direccion.codigoPostal },
      ].map(e => ({
        label: Object.keys(e)[0],
        value: Object.values(e)[0],
      }))
    }
  }, [holderData, state.provincies])

  const coverageData = useMemo(() => {
    if (holderData) {
      return [
        { planSeleccionado: holderData.plan },
        { aporte: holderData.aporte },
        { estadoDesregulado: pymeStatesLabel[holderData.estado] },
      ].map(e => ({
        label: Object.keys(e)[0],
        value: Object.values(e)[0],
      }))
    }
  }, [holderData])

  const familyMemberState = props.familyGroupDetail?.socios[0].estado || 0

  const descripcion = () => {
    let match = ''
    const memberStatus = props.familyGroupDetail?.socios[0].estado
    for (let i = 0, len = memberStates.length; i < len; i++) {
      if (memberStates[i].id === memberStatus) {
        match = memberStates[i].descripcion
      }
    }
    return match
  }

  const handleSubmit = (values: any) => {
    dispatch(changesInStatesAndObs(values))
  }

  useEffect(() => {
    localDispatch({
      type: HolderScreenActions.SET_FAMILY_MEMBER,
      payload:
        props.familyGroupDetail?.socios.find(
          s => s.id === state.dropDownValue.value
        ) || null,
    })
  }, [
    state.dropDownValue.value,
    localDispatch,
    props.familyGroupDetail?.socios,
    familyMemberOptions,
  ])

  useEffect(() => {
    localDispatch({
      type: HolderScreenActions.SET_PROVINCIES,
      payload: props.provincies,
    })
  }, [localDispatch, props.provincies])

  useEffect(() => {
    localDispatch({
      type: HolderScreenActions.SET_DROPDOWN_VALUE,
      payload: familyMemberOptions[0],
    })
  }, [familyMemberOptions])

  const changesSuccess = useSelector(selectChangesSuccess)

  useEffect(() => {
    dispatch(getStates({ idTipoEstado: 3, subTipo: 2 }))
  }, [dispatch])

  return (
    <S.HolderScreenContainer>
      <div
        onClick={props.onPressBack}
        style={{ textDecoration: 'unset', cursor: 'default' }}
      >
        <C.Row alignItems={'center'}>
          <C.IconLeftArrowAlt centered={false} color={'cornflowerBlue'} />
          <C.Text size={'sm'}>Volver al listado de grupos familiares</C.Text>
        </C.Row>
      </div>
      <S.HolderScreenContent>
        <S.FirstColumn>
          <C.Acordion title="Grupo Familiar" open>
            <S.GroupData>
              <C.Text size={'sm'}>
                Seleccioná el integrante del grupo que necesites visualizar
              </C.Text>
              <C.TableDropdown
                options={familyMemberOptions}
                value={state.dropDownValue}
                onChange={v =>
                  localDispatch({
                    type: HolderScreenActions.SET_DROPDOWN_VALUE,
                    payload: v,
                  })
                }
                id={'familyMemberDropDown'}
                name={'familyMemberDropDown'}
                adaptable
                bordered
              />
            </S.GroupData>
            <S.GroupData>
              <C.Text size={'md'} weight="md">
                Parentezco
              </C.Text>
              <C.LineHR />
              <C.Text size="sm" weight="md" color="cornflowerBlue">
                {state.familyMemberMappedKeyValueData?.familyMemberType}
              </C.Text>
            </S.GroupData>
            <S.GroupData>
              <C.Text size="md" weight="md">
                Datos personales
              </C.Text>
              <C.LineHR />
              {state.familyMemberMappedKeyValueData?.personalData.map(
                (e: any) => (
                  <C.KeyValue key={e.label} label={e.label} value={e.value} />
                )
              )}
            </S.GroupData>
            <S.GroupData>
              <C.Text size="md" weight="md">
                Domicilio
              </C.Text>
              <C.LineHR />
              {addressData?.map((e: any) => (
                <C.KeyValue key={e.label} label={e.label} value={e.value} />
              ))}
            </S.GroupData>
            <S.GroupData>
              <C.Text size="md" weight="md">
                Cobertura
              </C.Text>
              <C.LineHR />
              {coverageData?.map((e: any) => (
                <C.KeyValue key={e.label} label={e.label} value={e.value} />
              ))}
            </S.GroupData>
            <S.GroupData>
              <C.Text size="md" weight="md">
                Integrantes del grupo
              </C.Text>
              <C.LineHR />
              <C.KeyValue
                label={'¿El grupo incluye a su pareja?'}
                value={props.familyGroupDetail?.incluyePareja}
              />
              <C.KeyValue
                label={'Cantidad de hijos'}
                value={props.familyGroupDetail?.cantHijos}
              />
            </S.GroupData>
          </C.Acordion>
          <ObservationsAccordion
            props={props}
            addObservationPermission={'addFamilyGroupObservations'}
            keyName={'new-observation-btn'}
            page="holder"
          />
          <C.Acordion title="Declaraciones juradas de salud" open>
            <S.AcordionContent>
              <C.Text>
                Seleccioná el integrante del grupo que necesites visualizar
              </C.Text>
              <C.Text
                textAlign="center"
                color="chathamsBlue"
                weight="md"
              >{`${state.familyMember?.apellido} ${state.familyMember?.nombre}`}</C.Text>
              {state.familyMember?.djs.respuestas.map(r => {
                return (
                  <>
                    <S.DjsRows>
                      <C.Text color="chathamsBlue" size="sm">
                        {r.pregunta}{' '}
                      </C.Text>
                      <C.Text weight="md">{r.resultado ? 'SI' : 'NO'}</C.Text>
                    </S.DjsRows>
                    <hr />
                  </>
                )
              })}
              <S.DjsRows>
                <C.Text color="chathamsBlue" size="sm">
                  {' '}
                  Altura (en cm){' '}
                </C.Text>
                <C.Text weight="md">{state.familyMember?.djs.altura}</C.Text>
              </S.DjsRows>
              <hr />
              <S.DjsRows>
                <C.Text color="chathamsBlue" size="sm">
                  {' '}
                  Peso (en kg){' '}
                </C.Text>
                <C.Text weight="md">{state.familyMember?.djs.peso}</C.Text>
              </S.DjsRows>
            </S.AcordionContent>
          </C.Acordion>
        </S.FirstColumn>
        <S.SecondColumn>
          <History props={props} page="holder" />
          <C.Acordion
            title="Estado"
            open
            headerButton={[
              <C.Permission
                permissionRequired={'modifyFamilyGroupState'}
                displayInauthorizedComponent={false}
              >
                <C.Button
                  onClick={() => {
                    setModal(!modal)
                  }}
                  outline
                  centered
                >
                  Modificar estado
                </C.Button>
              </C.Permission>,
            ]}
          >
            <C.KeyValue
              label="Estado"
              value={changesSuccess !== null ? descripcion() : descripcion()}
            />
            <C.KeyValue
              label="Motivo"
              value={props.familyGroupDetail?.socios[0].motivo}
            />
          </C.Acordion>
        </S.SecondColumn>
      </S.HolderScreenContent>
      <StatesModal
        onSubmit={handleSubmit}
        show={modal}
        setShow={setModal}
        idEstado={familyMemberState}
      />
      {changesSuccess ? (
        <S.MessageContainer>
          <SavedChangesMsg variant="success" showMsg={changesSuccess}>
            Cambios guardados
          </SavedChangesMsg>
        </S.MessageContainer>
      ) : (
        ''
      )}
    </S.HolderScreenContainer>
  )
}

export default HolderDataScreen
