import { ILogger } from 'domain/entities'
import { Company } from 'domain/entities/companyPAD'

import {
  changeStateFGParams,
  changeStatusGFParams,
  createCompanyParam,
  fileParams,
  getCompaniesRequestModel,
  getCompanyRequestParam,
  getCompanyStateDisponiblesParam,
  getFamilyGroupDetailParam,
} from 'domain/requestModel/companyPAD'

import { getCompaniesToModel } from 'domain/responseModel/companyPAD'
import { endpoints } from 'app/constants'
import axios from 'axios'

export class CompanyPADService {
  private httpClient: any
  private logger: any
  private getCompaniesParams: getCompaniesRequestModel | null

  constructor(clientProvider: any, logger: ILogger) {
    this.httpClient = clientProvider
    this.logger = logger
    this.getCompaniesParams = null
  }

  public async getCompanies(
    searchParamas: getCompaniesRequestModel
  ): Promise<any> {
    try {
      this.getCompaniesParams = searchParamas

      const search = (
        await this.httpClient.get(
          endpoints.getCompanyRequest,
          this.getCompaniesParams
        )
      ).data

      return getCompaniesToModel({
        resultados: search.resultados.map((c: Company) => ({
          id: c.id,
          razonSocial: c.razonSocial,
          cuit: c.cuit,
          guid: c.guid,
          dni: c.dni,
          fechaIngreso: c.fechaIngreso,
          nombre: c.nombre,
          apellido: c.apellido,
          estado: c.estado,
        })),
        totalPaginas: search.totalPaginas,
        totalCantidad: search.totalCantidad,
      })
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Company (getCompanies)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })
    }
  }

  public async getCompany(param: getCompanyRequestParam): Promise<any> {
    try {
      const company = await this.httpClient.get(endpoints.getCompany, {
        id: param.companyId,
      })

      return company.data
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Company (getCompany)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })
    }
  }

  public async getCompanyStates(): Promise<any> {
    try {
      const companyState = await this.httpClient.get(endpoints.estadosPAD, {
        idTipoEstado: 5,
      })

      return companyState.data
    } catch (error) {
      this.logger.error({
        message: 'UseCase - CompanyState (getCompanyState)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })
    }
  }

  public async getCompanyStatesDisponibles(
    param: getCompanyStateDisponiblesParam
  ): Promise<any> {
    try {
      const companyState = await this.httpClient.get(
        endpoints.estadosDisponiblePAD,
        {
          idEstado: 0,
          idTipoEstado: 5,
        }
      )

      return companyState.data
    } catch (error) {
      this.logger.error({
        message: 'UseCase - CompanyState (getCompanyState)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })
    }
  }

  public async getExportCompanyList(): Promise<any> {
    try {
      const report = (
        await this.httpClient.get(
          endpoints.getExportCompaniesList,
          this.getCompaniesParams,
          {
            responseType: 'blob',
          }
        )
      ).data

      let url = window.URL.createObjectURL(report)
      let a = document.createElement('a')
      a.href = url
      a.download = 'listado-de-pymes.xlsx'
      a.click()
    } catch (error) {
      this.logger.error({
        message: 'UseCase - getExportCompanyList (getExportCompanyList)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })
    }
  }

  public async getFamilyGroupDetail(
    param: getFamilyGroupDetailParam
  ): Promise<any> {
    try {
      const familyGroupDetail = await this.httpClient.get(
        `${endpoints.getFamilyGroupDetailPAD}/${param.guid}/detalle`
      )

      return familyGroupDetail?.data
    } catch (error) {
      this.logger.error({
        message: 'UseCase - FamilyGroupDetail (getFamilyGroupDetail)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })
    }
  }

  public async changeStateObsFG(param: changeStateFGParams): Promise<any> {
    try {
      const changes = await this.httpClient.post(endpoints.eventChangeFG, param)
      return changes?.data
    } catch (error) {
      this.logger.error({
        message: 'UseCase - CompanyServices (changeStateObsFG)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })
    }
  }

  public async changeStatusGF(param: changeStatusGFParams): Promise<any> {
    try {
      const changes = await this.httpClient.post(
        endpoints.statusChangeFG,
        param
      )

      return changes?.data
    } catch (error) {
      this.logger.error({
        message: 'UseCase - CompanyServices (changeStateObsFG)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }
  public async saveDocumentation(param: fileParams): Promise<any> {
    try {
      const formData = new FormData()
      formData.append('Archivo', param?.archivo)
      formData.append('DNITitular', param?.dniTitular)
      formData.append('TipoArchivo', param?.tipoArchivo)

      const changes = await this.httpClient.postMultipart(
        endpoints.documentation,
        formData
      )

      return changes?.data
    } catch (error) {
      this.logger.error({
        message: 'UseCase - CompanyServices (changeStateObsFG)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })
      throw new Error(error)
    }
  }

  public async createCompany(param: createCompanyParam): Promise<any> {
    try {
      const company = await this.httpClient.post(
        endpoints.createCompany + `?id=${param.id}`
      )

      return company
    } catch (error) {
      this.logger.error({
        message: 'UseCase - CompanyServices (createCompany)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })
    }
  }

  public async downloadDocumentation(
    url: string,
    token: string,
    filename: string
  ): Promise<any> {
    try {
      const company = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      })

      const fileURL = window.URL.createObjectURL(new Blob([company.data]))
      const link = document.createElement('a')
      link.href = fileURL
      link.setAttribute('download', filename) // Nombre del archivo a descargar
      document.body.appendChild(link)
      link.click()

      // Limpiar el DOM y liberar la URL del blob
      document.body.removeChild(link)
      window.URL.revokeObjectURL(fileURL)

      return company
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Download (createCompany)',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })
    }
  }
}

export default {
  CompanyPADService,
}
