import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InputIcon, Button, Text } from 'app/components'
import * as S from './styles'

import {
  createCompany,
  getCompany,
  selectCompany,
  selectCreateCompany,
} from 'app/slices/company'
import * as C from 'app/components'
import Modals from './ModalContent/index'
import {
  getDocumentationStates,
  getAvailablePymeStates,
  postChangeStatePyme,
  postChangeStatePaperModePyme,
} from 'app/slices/stateSlice'
import {
  selectStatusDocs,
  selectAvailablesPymeStates,
  postUpdateDocumentationState,
  selectPostEvent,
  selectPymeStatusError,
  isLoadingSelectPymeStatus,
} from 'app/slices/stateSlice'
import {
  TPostStateDocumentsParam,
  TPostUpdateStatePymeParam,
} from 'domain/requestModel'
import { pymeStates as PYME_STATES } from 'app/constants/states'
import { getCompanyRequestParam } from 'domain/requestModel/company'
const ApplicationData = ({
  idPyme,
  idStatusPyme,
  idStatusDocumentation,
  infoSubmitted,
  status,
}: any) => {
  const dispatch = useDispatch()
  const docsStates = useSelector(selectStatusDocs)
  const pymeStates = useSelector(selectAvailablesPymeStates)

  const postUpdateData: boolean = useSelector(selectPostEvent)
  const company = useSelector(selectCompany)
  const isLoadingPymeStatus = useSelector(isLoadingSelectPymeStatus)
  const pymeStatusError = useSelector(selectPymeStatusError)
  const createdCompany = useSelector(selectCreateCompany)

  let idStatDoc = Number(idStatusDocumentation)
  const { handleModalContent, handleDisplayModal } = useContext<any>(
    C.ModalContext
  )

  const isRegisterButtonDisabled = !(
    (company?.idEstado === PYME_STATES.APROBADO &&
      company?.idEstadoDocumentacion === PYME_STATES.DOCUMENTACION_COMPLETA) ||
    (company?.idEstado === PYME_STATES.ALTA_EMPRESA_FALLIDA &&
      company?.idEstadoDocumentacion === PYME_STATES.DOCUMENTACION_COMPLETA)
  )

  const isPaperModeButtonDisabled =
    company?.idEstado === PYME_STATES.ALTA_EMPRESA_PARCIAL ||
    company?.idEstado === PYME_STATES.ALTA_EMPRESA_OK ||
    company?.idEstado === PYME_STATES.APROBADO

  useEffect(() => {
    if (idStatDoc) {
      dispatch(getDocumentationStates({ idEstado: idStatDoc }))
    }
  }, [dispatch, idStatDoc])

  useEffect(() => {
    if (idStatusPyme) {
      dispatch(getAvailablePymeStates({ idEstado: idStatusPyme }))
    }
  }, [dispatch, idStatusPyme])

  useEffect(() => {
    if (postUpdateData) {
      const idParam: getCompanyRequestParam = {
        companyId: idPyme,
      }
      dispatch(getCompany(idParam))
    }
  }, [postUpdateData, dispatch, idPyme])

  const handleOnPressStateUpdate = () => {
    handleDisplayModal(true)
    handleModalContent(
      <Modals.StateDocumentsModalContent
        availableStates={docsStates}
        onSubmit={values => {
          const param: TPostStateDocumentsParam = {
            estado: values?.state?.value,
            idPyme: idPyme,
          }
          handleDisplayModal(false)
          dispatch(postUpdateDocumentationState(param))
        }}
        onCancel={() => handleDisplayModal(false)}
        documentState={company.estadoDocumentacion}
      />
    )
  }

  const handleOnPressCompanyUpdate = () => {
    handleDisplayModal(true)
    handleModalContent(
      <Modals.StateCompanyModalContent
        availableStates={pymeStates}
        onSubmit={values => {
          const param: TPostUpdateStatePymeParam = {
            idPyme: idPyme,
            estado: values?.state?.value,
            observaciones: '',
          }
          handleDisplayModal(false)
          dispatch(postChangeStatePyme(param))
        }}
        onCancel={() => handleDisplayModal(false)}
        companyState={company.estado}
      />
    )
  }

  const handleOnPressPaperMode = () => {
    handleDisplayModal(true)
    handleModalContent(
      <Modals.PaperModeModalContent
        onPressConfirmCancel={() => {
          const param: any = {
            idPyme,
            estado: PYME_STATES.PASA_PAPEL_AMBITO_ACTUACION,
            observaciones: '',
          }
          dispatch(postChangeStatePaperModePyme(param))
          handleDisplayModal(false)
        }}
        onPressCancel={() => {
          handleDisplayModal(false)
        }}
      />
    )
  }

  const handleOnPressRegisterNewCompany = () =>
    dispatch(createCompany({ id: idPyme }))

  return (
    <>
      <div>
        <Text textAlign={'center'} size={'xl'} weight={'md'}>
          Datos de la solicitud:
        </Text>
      </div>
      <S.RowCenter>
        <div>
          <label>¿Documentación enviada?</label>
          <S.InputWrapper>
            <InputIcon>
              <C.Permission
                permissionRequired={'changeCompanyDocumentationState'}
                displayInauthorizedComponent={false}
              >
                {isPaperModeButtonDisabled ? null : (
                  <S.IconWrap onClick={handleOnPressStateUpdate}>
                    <S.BlueEditOutline />
                  </S.IconWrap>
                )}
              </C.Permission>
              <p>{infoSubmitted || 'sin cargar'}</p>
            </InputIcon>
          </S.InputWrapper>
          <C.Permission
            permissionRequired="changeCompanyPaperState"
            displayInauthorizedComponent={false}
          >
            <S.PaperModeButtonContainer>
              <Button
                disabled={isPaperModeButtonDisabled}
                outline={true}
                onClick={handleOnPressPaperMode}
                isLoading={isLoadingPymeStatus}
              >
                Modalidad Papel
              </Button>
              <S.ErrorMsg>{pymeStatusError}</S.ErrorMsg>
            </S.PaperModeButtonContainer>
          </C.Permission>
        </div>
        <div>
          <label>Estado</label>
          <S.InputWrapper>
            <C.Permission
              permissionRequired={'changeCompanyState'}
              displayInauthorizedComponent={false}
            >
              <InputIcon>
                {isPaperModeButtonDisabled ? null : (
                  <S.IconWrap onClick={handleOnPressCompanyUpdate}>
                    <S.BlueEditOutline />
                  </S.IconWrap>
                )}
                <p>{status === undefined ? 'sin estado' : status}</p>
              </InputIcon>
            </C.Permission>
          </S.InputWrapper>
          <C.Permission
            permissionRequired="registerNewCompany"
            displayInauthorizedComponent={false}
          >
            <Button
              disabled={isRegisterButtonDisabled}
              onClick={handleOnPressRegisterNewCompany}
              isLoading={createdCompany.isLoading}
            >
              Dar de alta la empresa
            </Button>
            <S.ErrorMsg>{createdCompany.error}</S.ErrorMsg>
          </C.Permission>
        </div>
      </S.RowCenter>
    </>
  )
}

export default ApplicationData
