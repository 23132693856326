import styled from 'styled-components'

import * as Components from 'app/components'

export const PageContent = styled.div`
  padding: 20px 15px;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    padding: 20px 45px;
    width: 100%;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`
export const SelectContainer = styled.fieldset`
  margin: 16px 0px;
  width: 100%;
  text-align: center;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Source Sans Pro;

  > div {
    margin: 0px;
  }

  .react-select__control {
    border: none;
    padding: 0;
    font-size: 0.75rem;
    margin: auto;
    float: right;
  }

  .react-select__control:hover {
    border: none;
    border-bottom: none;
    cursor: pointer;
  }

  .react-select__single-value {
    color: ${props => props.color} !important;
    font-weight: bold;
    font-size: 18px;
  }

  .react-select__placeholder {
    color: ${props =>
      props.color && props.theme.colors[`'${props.color}'`]} !important;
    font-weight: bold;
    font-size: 18px;
  }

  .react-select__menu {
    width: 100%;
    text-align: center;
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 12%);
    border-radius: 4px;
    font-family: Source Sans Pro;
    margin-rigth: 10px;
    margin-top: 6px;
    backgroun-color: white !important;
    font-weight: bold;
    font-size: 18px;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    font-size: 14px;

    .react-select__menu {
      width: 150px;
      font-size: 14px;
    }
  }
`

export const ObservationFormContainer = styled.div`
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`

export const ButtonsContainer = styled(Components.Row)`
  > button {
    margin: 0px 20px;
  }
`

export const PressBack = styled.div`
  textdecoration: unset;
  cursor: pointer;
`
