import { ILogger } from 'domain/entities'

import {
  getAfilliationsDTO,
  IAfilliationsDTO,
  TGetAfilliationDTO,
} from 'domain/responseModel'

import {
  TGetAfilliationsParams,
  TGetAfilliationParams,
  getAfilliationsByFilter,
  TPostAfilliationStateParams,
  TPostEventParam,
  postEvent,
  TPostEvent,
  TGetReportMedicArea,
} from 'domain/requestModel'

import { endpoints } from 'app/constants'
import { getReportMedicAreaDates } from 'domain/requestModel/afilliation'

export class AfilliationServices {
  private httpClient: any
  private logger: any

  constructor(clientProvider: any, logger: ILogger) {
    this.httpClient = clientProvider
    this.logger = logger
  }

  public async getAfilliations(
    params: TGetAfilliationsParams
  ): Promise<IAfilliationsDTO> {
    try {
      const filters = getAfilliationsByFilter(params)
      const afiliaciones: IAfilliationsDTO = (
        await this.httpClient.get(endpoints.afilliations, filters)
      ).data
      return getAfilliationsDTO(afiliaciones)
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Afilliaciones',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async getAfilliation(
    params: TGetAfilliationParams
  ): Promise<TGetAfilliationDTO> {
    try {
      const url = params.tipoPlan
        ? `${endpoints.afilliation}?idAfiliacion=${params.idAfiliacion}&tipoPlan=${params.tipoPlan}`
        : `${endpoints.afilliation}?idAfiliacion=${params.idAfiliacion}`

      const afiliacion = (await this.httpClient.get(url)).data

      return afiliacion
    } catch (error) {
      this.logger.error({
        message: 'UseCase - Afilliacion',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })
      throw new Error(error)
    }
  }

  public async postAfilliationStateUpdate(params: TPostAfilliationStateParams) {
    try {
      const tipoPlan =
        params.tipoPlan !== undefined
          ? params.tipoPlan
          : await this.getAfiliacionTipoPlan(params.idAfiliacion)

      const stateUpdate: any = (
        await this.httpClient.post(
          `${endpoints.stateUpdate}${
            tipoPlan !== undefined ? `?tipoPlan=${tipoPlan}` : ''
          }`,
          params
        )
      ).data

      return stateUpdate
    } catch (error) {
      this.logger.error({
        message: 'UseCase - State Update',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })
      throw new Error(error)
    }
  }

  private async getAfiliacionTipoPlan(
    idAfiliacion: number
  ): Promise<number | undefined> {
    try {
      const afiliacion = await this.getAfilliation({
        idAfiliacion: idAfiliacion.toString(),
      })
      const tipoPlan = afiliacion.afiliacion.tipoPlan

      if (typeof tipoPlan === 'number') {
        return tipoPlan
      } else if (typeof tipoPlan === 'string' && !isNaN(Number(tipoPlan))) {
        return Number(tipoPlan)
      } else {
        this.logger.warn({
          message: 'tipoPlan no es un número válido',
          data: { tipoPlan },
        })
        return undefined
      }
    } catch (error) {
      this.logger.error({
        message: 'Error al obtener el tipoPlan de la afiliación',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })
      return undefined
    }
  }

  public async postEvent(params: TPostEventParam) {
    try {
      const mapPostEventParams: TPostEvent = postEvent(params)

      const endpoint =
        params.tipoPlan === '5'
          ? `${endpoints.evento}?tipoPlan=5`
          : endpoints.evento

      const newEvent: IAfilliationsDTO = (
        await this.httpClient.post(endpoint, mapPostEventParams)
      ).data

      return newEvent
    } catch (error) {
      this.logger.error({
        message: 'UseCase - postEvent',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async getReportMedicArea(params: TGetReportMedicArea) {
    try {
      const param = getReportMedicAreaDates(params)
      const report = (
        await this.httpClient.get(endpoints.medicAreaReport, param, {
          responseType: 'blob',
        })
      ).data

      let url = window.URL.createObjectURL(report)
      let a = document.createElement('a')
      a.href = url
      a.download = 'reporte-area-medica.xlsx'
      a.click()
    } catch (error) {
      this.logger.error({
        message: 'UseCase - getReportMedicArea',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }

  public async getDetailMedicArea(params: TGetReportMedicArea) {
    try {
      const param = getReportMedicAreaDates(params)
      const report = (
        await this.httpClient.get(endpoints.medicAreaDetail, param, {
          responseType: 'blob',
        })
      ).data

      let url = window.URL.createObjectURL(report)
      let a = document.createElement('a')
      a.href = url
      a.download = 'detalle-area-medica.xlsx'
      a.click()
    } catch (error) {
      this.logger.error({
        message: 'UseCase - getDetailMedicArea',
        data: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      })

      throw new Error(error)
    }
  }
}

export default {
  AfilliationServices,
}
