import React, { useEffect, useState, useContext, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import {
  getAfilliation,
  selectAfilliation,
  postEvent,
  selectPostEventIsFetching,
  selectPostEvent,
  selectAfilliationIsFetching,
  postAfilliationStateUpdate,
  selectAfilliationAndAssociateForDropdown,
  getAfilliationsByFilters,
} from 'app/slices/afilliationSlice'
import { getDniByDocNumber, selectAuthIsLoading } from 'app/slices/dniSlice'
import {
  selectStatesForDropdowns,
  getAvailableStates,
  selectAvailableStates,
} from 'app/slices/stateSlice'

import {
  TPostAfilliationStateParams,
  TPostEventParam,
} from 'domain/requestModel'

import * as C from 'app/components'

import Modals from './ModalContent'

import * as S from 'app/Pages/Afilliation/styles'
import * as presenters from './presenters'

import Sections from './sections'

import constants, { TForm, TStateForm } from './constants'
import { AfilliationAssociate } from 'domain/entities'
import { initialValues } from '../Afilliations/constants'

const Afilliation = (props: any) => {
  const dispatch = useDispatch()
  const params = useParams<{ idAfiliacion?: string }>()
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const tipoPlan = searchParams.get('tipoPlan') ?? ''

  const idAfiliacion = params.idAfiliacion || ''

  const newEventAdded = useRef(false)
  const [socio, setSocio] = useState<any>({})
  const [detailData, setDetailData] = useState<any>({})
  const [selectedOption, setSelectedOption] = useState(
    constants.nameSelectInitialValue
  )

  const afilliation = useSelector(selectAfilliation)
  const authIsLoading: boolean = useSelector(selectAuthIsLoading)
  const postEventIsFetching: boolean = useSelector(selectPostEventIsFetching)
  const postEventData: boolean = useSelector(selectPostEvent)
  const afilliationIsFetching: boolean = useSelector(
    selectAfilliationIsFetching
  )
  const availableStates: any = useSelector(selectAvailableStates)
  const afilliationAndAssociateForDropdown = useSelector(
    selectAfilliationAndAssociateForDropdown
  )
  const backofficeStates = useSelector(selectStatesForDropdowns)

  const {
    handleModalContent,
    handleDisplayModal,
    handleOnModalIsFetching,
  } = useContext(C.ModalContext)

  useEffect(() => {
    if (idAfiliacion) {
      dispatch(
        getAfilliation({
          idAfiliacion: idAfiliacion,
          tipoPlan: tipoPlan ? Number(tipoPlan) : undefined,
        })
      )
    }
  }, [idAfiliacion, dispatch, tipoPlan])

  useEffect(() => {
    if (afilliation?.afiliacion?.estadoBackOffice) {
      const getAvailableStatesParams = {
        idEstado: afilliation.afiliacion.estadoBackOffice,
        ...(tipoPlan === '5' && { tipoPlan: 5 }),
      }
      dispatch(getAvailableStates(getAvailableStatesParams))
    }
  }, [dispatch, afilliation, tipoPlan])

  useEffect(() => {
    if (afilliationAndAssociateForDropdown.length > 0) {
      setSelectedOption(afilliationAndAssociateForDropdown[0])
    }
  }, [afilliationAndAssociateForDropdown])

  useEffect(() => {
    if (afilliation) {
      setDetailData(
        presenters.detailViewModel({
          association: afilliation.afiliacion,
          associate: afilliation.socios,
          associateId: selectedOption.value,
          backofficeStates: backofficeStates,
        })
      )
    }
  }, [afilliation, selectedOption, backofficeStates])

  useEffect(() => {
    if (afilliation) {
      const socio = afilliation.socios.find(
        (s: AfilliationAssociate) => s.id === selectedOption.value
      )
      setSocio(socio || {})
    }
  }, [selectedOption, afilliation])

  useEffect(() => {
    handleOnModalIsFetching(postEventIsFetching)
  }, [postEventIsFetching, handleOnModalIsFetching])

  useEffect(() => {
    if (postEventData && newEventAdded.current) {
      dispatch(
        getAfilliation({
          idAfiliacion: idAfiliacion,
          tipoPlan: tipoPlan === '5' ? tipoPlan : undefined,
        })
      )
      handleDisplayModal(false)
      newEventAdded.current = false
    }
  }, [
    postEventData,
    dispatch,
    handleDisplayModal,
    idAfiliacion,
    newEventAdded,
    tipoPlan,
  ])

  const handleOnPressDownloadDni = (dniNumber: string) =>
    dispatch(getDniByDocNumber(dniNumber))

  const handleOnPressModalSubmit = (values: TForm) => {
    const param: TPostEventParam = {
      afilliationEvent: values.observations,
      observation: values.detail,
      idAfilliation: parseInt(idAfiliacion),
      tipoPlan: tipoPlan,
    }
    newEventAdded.current = true
    dispatch(postEvent(param))
  }

  const handleOnPressAddNewObservation = () => {
    handleDisplayModal(true)
    handleModalContent(
      <Modals.ObservationModalContent
        onSubmit={handleOnPressModalSubmit}
        onCancel={() => handleDisplayModal(false)}
      />
    )
  }

  const handleOnPressStateUpdateSubmit = (values: TStateForm) => {
    const param: TPostAfilliationStateParams = {
      idAfiliacion: parseInt(idAfiliacion),
      observaciones: values.observations,
      estado: values.state.value,
      tipoPlan: tipoPlan || undefined,
    }
    newEventAdded.current = true
    dispatch(postAfilliationStateUpdate(param))
  }

  const handleOnPressStateUpdate = () => {
    handleDisplayModal(true)
    handleModalContent(
      <Modals.StateModalContent
        availableStates={availableStates}
        onSubmit={handleOnPressStateUpdateSubmit}
        onCancel={() => handleDisplayModal(false)}
      />
    )
  }
  const history = useHistory()

  const onPressBack = () => {
    dispatch(getAfilliationsByFilters(initialValues))
    history.goBack()
  }
  return (
    <S.PageContent>
      {afilliationIsFetching ? (
        <C.LoadingSpinner isLoading={afilliationIsFetching} />
      ) : (
        <>
          <S.PressBack onClick={onPressBack}>
            <C.Row alignItems={'center'}>
              <C.IconLeftArrowAlt centered={false} color={'cornflowerBlue'} />
              <C.Text size={'sm'}>Volver al listado</C.Text>
            </C.Row>
          </S.PressBack>
          <S.DetailsContainer>
            <S.Column>
              <Sections.Details
                formInitialValues={constants.detailFormInitialValues(
                  afilliationAndAssociateForDropdown
                )}
                detailData={detailData}
                authIsLoading={authIsLoading}
                onPressDownloadDni={handleOnPressDownloadDni}
                dropdownOptions={afilliationAndAssociateForDropdown}
                setSelectedOption={setSelectedOption}
                tipoPlan={tipoPlan}
              />
              <C.Acordion title={'DDJJs'} data={socio?.djs?.respuestas} />
              {tipoPlan === '5' && (
                <Sections.Documentation
                  data={afilliation ? afilliation : []}
                  tipoPlan={tipoPlan}
                />
              )}
            </S.Column>
            <S.Column>
              <C.Acordion
                title={'Historial'}
                data={afilliation?.historial || []}
              />
              <Sections.Observations
                onPressAddNewObservation={handleOnPressAddNewObservation}
                data={afilliation ? afilliation.observaciones : []}
              />
              <Sections.StateUpdate
                onPressStateUpdate={handleOnPressStateUpdate}
                data={{
                  estado: afilliation?.afiliacion?.estadoBODescripcion || '',
                  motivo: afilliation?.afiliacion?.motivo || '',
                }}
              />
            </S.Column>
          </S.DetailsContainer>
        </>
      )}
    </S.PageContent>
  )
}

export default Afilliation
