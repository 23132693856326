import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { TGetAdvisorByNameOrCode } from 'domain/requestModel'

import AfilliationForm from 'app/Pages/Afilliations/AfilliationForm'
import AfilliationTable from 'app/Pages/Afilliations/Table'
import { viewModelAfilliations } from 'app/Pages/Afilliations/presenters'

import {
  getStateBackofficeNormalize,
  selectAvailableStates,
} from 'app/slices/stateSlice'
import { getAllProvincies, selectProvincies } from 'app/slices/provinceSlice'
import {
  getAdvisorByNameOrCode,
  selectAdvisorFilteredByNameOrCode,
} from 'app/slices/advisorSlice'

import {
  getAfilliationsByFilters,
  selectAfilliations,
  selectAfilliationsNumberOfPages,
} from 'app/slices/afilliationSlice'

interface IAfilliationsPage {
  path: string
}

const Afilliations = (props: IAfilliationsPage) => {
  const dispatch = useDispatch()
  const selectedAfilliations = useSelector(selectAfilliations)
  const states = useSelector(selectAvailableStates)
  const allProvincies = useSelector(selectProvincies)
  const advisors = useSelector(selectAdvisorFilteredByNameOrCode)
  const afilliationsNumberOfPages = useSelector(selectAfilliationsNumberOfPages)
  let history = useHistory()

  const affiliationTableData = viewModelAfilliations({
    afilliations: selectedAfilliations.afilliations.resultados,
    states,
    provincies: allProvincies,
  })

  useEffect(() => {
    dispatch(getAllProvincies())
  }, [dispatch])

  useEffect(() => {
    dispatch(getStateBackofficeNormalize())
  }, [dispatch])

  const handleOnChangeAdvisor = (codeOrName: any) => {
    let param: TGetAdvisorByNameOrCode | any = {}

    isNaN(codeOrName)
      ? (param['nombre'] = codeOrName)
      : (param['codAsesor'] = codeOrName)

    dispatch(getAdvisorByNameOrCode(param))
  }

  const handleOnSubmit = (values: any) =>
    dispatch(getAfilliationsByFilters(values))

  const handleOnPressItem = (element: any, key: any) => {
    const idAfiliacion = element.detalle
    const tipoPlan = element.tipoAfiliacion

    if (tipoPlan) {
      history.push(`afiliaciones/${idAfiliacion}?tipoPlan=${tipoPlan}`)
    } else {
      history.push(`afiliaciones/${idAfiliacion}`)
    }
  }

  return (
    <>
      <AfilliationForm
        afilliations={selectedAfilliations.afilliations}
        states={states}
        provincies={allProvincies}
        onSubmit={handleOnSubmit}
        onChangeAdvisor={handleOnChangeAdvisor}
        advisors={advisors.advisors}
        advisorsIsFetching={advisors.loading}
        paginationPageCount={afilliationsNumberOfPages}
      >
        <AfilliationTable
          data={affiliationTableData}
          isLoading={selectedAfilliations.loading}
          onPressItem={handleOnPressItem}
        />
      </AfilliationForm>
    </>
  )
}

export default Afilliations
