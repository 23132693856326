import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import { Button, Text } from 'app/components'
import { SelectAsync } from 'app/components'
import { initialValuesStatus } from './constants'
import {
  getFamilyGroupDetail,
  selectChangesStatusGFIsFetching,
} from 'app/slices/companyPAD'

import * as S from './styles'
import * as C from 'app/components'
import { strings } from 'app/constants/strings'
import { colors } from 'app/styles/colors'
import successIcon from 'app/images/Ok.svg'
import errorIcon from 'app/images/Error.svg'
import { IconClose } from 'app/components/Icons'
import {
  getAcuerdos,
  selectAcuerdos,
  selectAcuerdosIsFetching,
  updateAcuerdo,
} from '../../slices/acuerdosSlice'

const {
  forms: {
    fieldsTitle: { acuerdoTitle },
  },
} = strings

interface IContractTypeModal {
  onSubmit?: (values: any) => void
  show: boolean
  setShow: (values: any) => void
  confirmationStatusApproved?: any
  codObraSocial?: string | null
}

const ContractTypeModal: FC<IContractTypeModal> = ({
  onSubmit,
  show,
  setShow,
  confirmationStatusApproved,
  codObraSocial,
}) => {
  const params = useParams<{ guid: string }>()
  const initVal = { ...initialValuesStatus, guid: params ? params.guid : '' }
  const dispatch = useDispatch()
  const isFetchingChanges = useSelector(selectChangesStatusGFIsFetching)
  const contractOptions = useSelector(selectAcuerdos)
  const isFetchingAcuerdos = useSelector(selectAcuerdosIsFetching)
  const [enabled, setEnabled] = useState(true)
  const [selectedValue, setSelectedValue] = useState<C.ISelectFormat | null>(
    null
  )
  const [acuerdoSuccess, setAcuerdoSuccess] = useState(false)

  useEffect(() => {
    if (codObraSocial) {
      dispatch(getAcuerdos({ codObraSocial }))
    }
  }, [codObraSocial, dispatch])

  const refresh = () => {
    dispatch(getFamilyGroupDetail(params))
  }

  const handleSubmit = async (values: any) => {
    try {
      await dispatch(
        updateAcuerdo({
          guidverificador: params.guid,
          acuerdo: values.idTipoContrato,
        })
      )
      setAcuerdoSuccess(true)
    } catch (error) {
      setAcuerdoSuccess(false)
      console.error('Error submitting form:', error)
    }
  }

  return (
    <>
      <Formik
        initialValues={initVal}
        onSubmit={(values: any, { setSubmitting }) => {
          setSubmitting(false)
          handleSubmit(values)
        }}
      >
        {props => (
          <Form>
            <S.ModalBg show={show}>
              {acuerdoSuccess ? (
                <S.ConfirmationStatus>
                  <>
                    <S.CloseIcon>
                      <IconClose
                        size={'sm'}
                        centered={false}
                        color={'gray'}
                        onClick={() => {
                          refresh()
                        }}
                        isCursor
                      />
                    </S.CloseIcon>
                  </>
                  <>
                    <S.ConfirmationIcon
                      src={
                        String(confirmationStatusApproved)?.includes('Error')
                          ? errorIcon
                          : successIcon
                      }
                      alt="success"
                    />
                    <Text
                      textAlign={'center'}
                      size={'mdlg'}
                      color={'chathamsBlue'}
                      weight={'md'}
                    >
                      {!acuerdoSuccess
                        ? 'No se ha podido modificar el acuerdo.'
                        : 'El tipo de acuerdo se ha modificado correctamente.'}
                    </Text>
                    {!acuerdoSuccess && (
                      <Text
                        textAlign={'center'}
                        size={'md'}
                        color={'chathamsBlue'}
                        weight={'md'}
                      >
                        Por favor, volvé a intentar
                      </Text>
                    )}

                    <S.ConfirmationButton>
                      <C.Button
                        type="button"
                        onClick={() => {
                          refresh()
                        }}
                      >
                        Aceptar
                      </C.Button>
                    </S.ConfirmationButton>
                  </>
                </S.ConfirmationStatus>
              ) : (
                <S.ModalBox>
                  {isFetchingChanges || isFetchingAcuerdos ? (
                    <C.LoadingSpinner
                      isLoading={isFetchingChanges || isFetchingAcuerdos}
                    />
                  ) : (
                    <>
                      <S.Title>
                        <Text
                          textAlign={'center'}
                          size={'xl'}
                          color={'cornflowerBlue'}
                          weight={'md'}
                        >
                          Editar tipo de acuerdo
                        </Text>
                      </S.Title>
                      <S.ContainerBox>
                        <S.InputsBox>
                          <SelectAsync
                            onChange={(option: any) => {
                              props.setFieldValue(
                                'idTipoContrato',
                                option ? option.label : null
                              )
                              setEnabled(false)
                              setSelectedValue(option)
                            }}
                            isClearable
                            title={acuerdoTitle}
                            name={'idTipoContrato'}
                            id={'idTipoContrato'}
                            placeholder="Seleccionar"
                            options={contractOptions}
                            value={selectedValue}
                            color={colors.cornflowerBlue}
                            fullWidth
                          />
                          {props.values.idTipoContrato === null ? (
                            <S.ErrorText>
                              {setEnabled(true)}
                              Debe seleccionar un tipo de acuerdo
                            </S.ErrorText>
                          ) : null}
                        </S.InputsBox>
                      </S.ContainerBox>
                      <S.ButtonsBox>
                        <Button
                          outline={true}
                          onClick={() => {
                            setShow(!show)
                            setSelectedValue(null)
                            props.resetForm()
                          }}
                          centered
                        >
                          Cancelar
                        </Button>
                        <Button type="submit" disabled={enabled} centered>
                          Guardar
                        </Button>
                      </S.ButtonsBox>
                    </>
                  )}
                </S.ModalBox>
              )}
            </S.ModalBg>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ContractTypeModal
