import { ISelectFormat } from 'app/components'
import { usersRelated } from 'app/constants'
import { asociateTypesStrings } from 'app/constants/relationShips'
import { Province } from 'domain/entities'
import { Socio } from 'domain/entities/familyGroupPADDetail'
import { DayMonthYearWithLeadingZerosDashed, getFormatedDate } from 'helpers'

export enum HolderScreenActions {
  SET_DROPDOWN_VALUE = 'SET_DROPDOWN_VALUE',
  SET_FAMILY_MEMBER = 'SET_FAMILY_MEMBER',
  SET_PROVINCIES = 'SET_PROVINCIES',
}

interface HolderScreenState {
  dropDownValue: ISelectFormat
  familyMember: Socio | undefined
  familyMemberMappedKeyValueData: any
  provincies: Province[] | undefined
}

interface HolderScreenAction {
  type: HolderScreenActions
  payload: any
}

const holderScreenReducer = (
  state: HolderScreenState,
  action: HolderScreenAction
): HolderScreenState => {
  const { type, payload } = action

  const convertirACadenaFecha = (cadena: string | undefined) => {
    if (cadena) {
      let [fecha, hora] = cadena.split(' ')

      fecha = fecha.split('/').reverse().join('-')

      let cadenaISO = `${fecha}T${hora}`

      return new Date(cadenaISO)
    }
  }

  switch (type) {
    case HolderScreenActions.SET_DROPDOWN_VALUE: {
      return {
        ...state,
        dropDownValue: payload,
      }
    }

    case HolderScreenActions.SET_FAMILY_MEMBER: {
      const socio = payload as Socio
      const dateNacimiento = socio?.fechaNacimiento
        ?.toString()
        .replace(/a\. m\./g, 'AM')
        .replace(/p\. m\./g, 'PM')
      const dateNacimientoFormated =
        convertirACadenaFecha(dateNacimiento) ?? new Date()

      const fechaIngreso = socio?.fechaIngreso
        ?.toString()
        .replace(/a\. m\./g, 'AM')
        .replace(/p\. m\./g, 'PM')
      const fechaIngresoFormated =
        convertirACadenaFecha(fechaIngreso) ?? new Date()

      const edadSocio = socio?.edad === 0 ? 'recien nacido' : socio?.edad
      return {
        ...state,
        familyMember: payload,
        familyMemberMappedKeyValueData: socio
          ? {
              familyMemberType: asociateTypesStrings[socio.tipoSocio],
              personalData: [
                { nroDocumento: socio.dni },
                { cuil: socio.cuil },
                { sexo: usersRelated.sexStrings[socio.sexo] },
                {
                  fechaDeNacimiento: getFormatedDate(
                    dateNacimientoFormated,
                    DayMonthYearWithLeadingZerosDashed
                  ),
                },
                { edad: edadSocio },
                { eMail: socio.mail },
                { phone: socio.telefonoMovil },
                {
                  fechaDeIngresoALaEmpresa:
                    fechaIngreso === ''
                      ? null
                      : getFormatedDate(
                          fechaIngresoFormated,
                          DayMonthYearWithLeadingZerosDashed
                        ),
                },
                {
                  recategorizado:
                    socio.tipoSocio === 1 || socio.tipoSocio === 6
                      ? null
                      : socio.recategorizado
                      ? 'Si'
                      : 'No',
                },
              ].map(e => ({
                label: Object.keys(e)[0],
                value: Object.values(e)[0],
              })),
            }
          : null,
      }
    }

    case HolderScreenActions.SET_PROVINCIES: {
      return {
        ...state,
        provincies: payload,
      }
    }

    default:
      return state
  }
}

export default holderScreenReducer
