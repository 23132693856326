import React from 'react'

import { Acordion, Button } from 'app/components'

type TObservations = {
  onPressAddNewObservation: () => void
  data: any[]
}

const Observations = (props: TObservations) => {
  const formattedData = props.data.map(observation => {
    const { observacion, ...rest } = observation
    return observacion ? { ...rest, observación: observacion } : observation
  })

  return (
    <Acordion
      title={'Observaciones'}
      open={true}
      headerButton={[
        <Button
          type="button"
          onClick={props.onPressAddNewObservation}
          outline
          disabled={false}
          isLoading={false}
          key={'new-observation-btn'}
        >
          Nueva observación
        </Button>,
      ]}
      data={formattedData}
    />
  )
}

export default Observations
