import React, { FC } from 'react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import styled from 'styled-components'
import { Button, Text } from 'app/components'
import { SelectAsync } from 'app/components'
import { initialValues } from './constants'
import {
  getAvalilableStatesBO,
  selectAvailableStatesBO,
} from 'app/slices/stateSlice'

import { selectChangesObsIsFetching } from 'app/slices/company'

import * as S from './styles'
import * as C from 'app/components'

import { strings } from 'app/constants/strings'
import TextArea from 'app/components/ui/forms/TextArea'
import { IconClose } from 'app/components'

const IconContainer = styled.div`
  position: absolute;
  margin-top: 0.1rem;
  margin-left: 38rem;
`

const {
  forms: {
    fieldsTitle: { stateTitle },
  },
} = strings

interface IStatesModal {
  onSubmit: (values: any) => void
  show: boolean
  setShow: (values: any) => void
  idEstado: number
}

const StatesModal: FC<IStatesModal> = ({
  onSubmit,
  show,
  setShow,
  idEstado,
}) => {
  const dispatch = useDispatch()
  const params = useParams<{ guid: string }>()
  const initVal = { ...initialValues, guid: params ? params.guid : '' }
  const [enabled, setEnabled] = useState(true)
  const availableStates = useSelector(selectAvailableStatesBO)
  const isFetchingChanges: boolean = useSelector(selectChangesObsIsFetching)

  useEffect(() => {
    dispatch(getAvalilableStatesBO({ idEstado }))
  }, [dispatch, idEstado])

  return (
    <>
      <Formik
        initialValues={initVal}
        onSubmit={(values: any, { setSubmitting }) => {
          setSubmitting(false)
          onSubmit(values)
        }}
      >
        {(props: {
          values: any
          errors: {
            estado: boolean
            observaciones: boolean
          }
          touched: {
            estado: boolean
            observaciones: boolean
          }
          onChange: any
          handleChange: any
          submitForm: any
          setFieldValue: any
        }) => (
          <Form>
            <S.ModalBg show={show}>
              <S.ModalBox>
                <IconContainer>
                  <IconClose
                    size="md"
                    onClick={() => setShow(!show)}
                    isCursor
                  />
                </IconContainer>

                {isFetchingChanges ? (
                  <C.LoadingSpinner isLoading={isFetchingChanges} />
                ) : (
                  <>
                    <S.Title>
                      <Text
                        textAlign={'center'}
                        size={'xl'}
                        color={'cornflowerBlue'}
                        weight={'md'}
                      >
                        Modificar estado del grupo familiar
                      </Text>
                    </S.Title>
                    <S.InputsBox>
                      <SelectAsync
                        onChange={(option: any) => {
                          props.setFieldValue(
                            'estado',
                            option ? option.value : null
                          )
                          setEnabled(false)
                        }}
                        isClearable
                        title={stateTitle}
                        name={'estado'}
                        id={'estado'}
                        placeholder="Seleccionar"
                        options={availableStates}
                      />
                      {props.values.estado === null ? (
                        <S.ErrorText>
                          {setEnabled(true)}
                          Debe seleccionar un estado
                        </S.ErrorText>
                      ) : null}
                      <TextArea
                        id="observaciones"
                        name="observaciones"
                        onChange={(event: any) => {
                          props.setFieldValue(
                            'observaciones',
                            event ? event.target.value : null
                          )
                        }}
                        title="Observaciones"
                      />
                    </S.InputsBox>
                    <S.ButtonsBox>
                      <Button outline={true} onClick={() => setShow(!show)}>
                        Cancelar
                      </Button>
                      <Button type="submit" disabled={enabled}>
                        Guardar
                      </Button>
                    </S.ButtonsBox>
                  </>
                )}
              </S.ModalBox>
            </S.ModalBg>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default StatesModal
