import { Afilliation, State, Province } from 'domain/entities'

import {
  getFormatedDate,
  DayMonthYearWithLeadingZerosDashed,
  numberWithSeparators,
} from 'helpers'

export type TAffiliationViewModel = {
  dni: string
  numeroSocio: string
  estadoBackOffice: string
  motivo: string
  nombre: string
  apellido: string
  provincia: string
  asesor: string
  nombreAsesor: string
  fechaCotizacion: string
  fechaVigencia: string
  tipoAfiliacion: number
}

type TAffiliationProps = {
  afilliations: Afilliation[]
  states: State[]
  provincies: Province[]
}

export const viewModelAfilliations = (
  props: TAffiliationProps
): TAffiliationViewModel[] => {
  return props.afilliations.map((e: Afilliation) => {
    const province: Province | undefined = props.provincies.find(
      p => p.id === e.idProvincia
    )

    return {
      detalle: e.id,
      dni: e.dni,
      numeroSocio: e.numeroSocio
        ? numberWithSeparators({ number: parseInt(e.numeroSocio) })
        : '',
      estadoBackOffice: e.estadoBODescripcion || '',
      motivo: e.motivo || '',
      tipoAfiliacion: e.tipoPlan,
      nombre: e.nombre,
      apellido: e.apellido,
      provincia: province?.nombre || '',
      asesor: e.asesor || '',
      nombreAsesor: e.nombreAsesor,
      fechaCotizacion: getFormatedDate(
        e.fechaCotizacion,
        DayMonthYearWithLeadingZerosDashed
      ),
      fechaVigencia: getFormatedDate(
        e.fechaVigencia,
        DayMonthYearWithLeadingZerosDashed
      ),
    }
  })
}
