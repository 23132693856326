import React, { useReducer, useState, useEffect, useMemo } from 'react'

import {
  selectFamilyMembersMappedToDropdown,
  changesInStatesAndObs,
  selectChangesSuccess,
  saveDocumentation,
  changeStatusGF,
  selectChangesStatusGFSuccess,
  getFamilyGroupDetail,
  selectSaveDocumentationSuccess,
} from 'app/slices/companyPAD'

import * as C from 'app/components'
import * as S from './styles'

import holderScreenReducer, { HolderScreenActions } from './State'
import { FamilyGroupDetail } from 'domain/entities/familyGroupPADDetail'
import { useSelector, useDispatch } from 'react-redux'

import StatesModal from './StatesModal'
import SavedChangesMsgPAD from 'app/components/SavedChangesMsgPAD'
import History from '../../components/ui/HistoryCard'
import { IconPaperClip } from 'app/components/Icons'
import DocumentationModal from './DocumentationModal'
import ObservationModal from './ObservationModal'
import Observations from './Component/Observations'
import ContractTypeModal from './acuerdoModal'
import { companyPADService } from 'app/services'
import { selectAuth } from 'app/slices/authSlice'

interface HolderDataScreenProps {
  onPressBack: () => void
  provincies: any
  familyGroupDetail: FamilyGroupDetail | null
  params: any
}

interface TypeAfiliation {
  [key: number]: string
}

const TYPE_AFILIATION: TypeAfiliation = {
  1: 'Obra Social',
  2: 'Monotributista',
  3: 'Directo',
}

const HolderDataCompanyScreen = (props: HolderDataScreenProps) => {
  const dispatch = useDispatch()
  const holderData = props.familyGroupDetail?.socios[0]
  const familyDetailData = props.familyGroupDetail
  const holderDataDireccion = props.familyGroupDetail?.direccion
  const [state, localDispatch] = useReducer(holderScreenReducer, {
    dropDownValue: { label: '', value: '' },
    familyMember: props.familyGroupDetail?.socios[0],
    familyMemberMappedKeyValueData: null,
    provincies: props.provincies,
  })
  const [modal, setModal] = useState(true)
  const [modalDocumentation, setModalDocumentation] = useState(true)
  const [modalObservation, setModalObservation] = useState(true)
  const [modalContract, setModalContract] = useState(true)
  const familyMemberOptions: C.ISelectFormat[] = useSelector(
    selectFamilyMembersMappedToDropdown
  )
  const [showObservations, setShowObservations] = useState(false)
  const [confirmationStatusApproved, setConfirmationStatusApproved] = useState(
    false
  )
  const { token } = useSelector(selectAuth)

  const seeMoreObservations = () => {
    setShowObservations(!showObservations)
  }

  const findKeyByValueTypeAfiliation = (
    obj: TypeAfiliation,
    key: number
  ): string | undefined => {
    return obj[key]
  }

  const addressData = useMemo(() => {
    if (holderDataDireccion) {
      return [
        { Calle: holderDataDireccion.calle || '-' },
        { Altura: holderDataDireccion.altura || '-' },
        {
          Piso: holderDataDireccion.piso || '-',
        },
        { Depto: holderDataDireccion.depto || '-' },
        {
          Provincia: holderDataDireccion.provincia || '-',
        },
        {
          Localidad: holderDataDireccion.localidad || '-',
        },
        { CP: holderDataDireccion.codigoPostal || '-' },
      ].map(e => ({
        label: Object.keys(e)[0],
        value: Object.values(e)[0],
      }))
    }
  }, [holderDataDireccion])

  const coverageData = useMemo(() => {
    if (holderData && familyDetailData) {
      return [
        {
          obraSocial:
            familyDetailData.tipoAfiliacion === 3
              ? null
              : familyDetailData?.obraSocial?.descripcion,
        },
        { planElegido: familyDetailData?.plan?.descripcion },
        { aportes: holderData.aporte.toString() },
      ].map(e => ({
        label: Object.keys(e)[0],
        value: Object.values(e)[0],
      }))
    }
  }, [holderData, familyDetailData])

  const familyMemberState = props.familyGroupDetail?.socios[0].estado || 0

  const handleSubmit = async (values: any) => {
    try {
      setConfirmationStatusApproved(false)
      await dispatch(changeStatusGF(values))
      dispatch(getFamilyGroupDetail(values))
    } catch (error) {
      setConfirmationStatusApproved(error.toString())
    }
  }

  const handleSubmitEvent = (values: any) => {
    dispatch(changesInStatesAndObs(values))
  }

  const handleSubmitDocumentation = (values: any) => {
    const isAfilliation = false
    dispatch(saveDocumentation(values, isAfilliation))
  }

  useEffect(() => {
    localDispatch({
      type: HolderScreenActions.SET_FAMILY_MEMBER,
      payload:
        props.familyGroupDetail?.socios.find(
          s => s.id === state.dropDownValue.value
        ) || null,
    })
  }, [
    state.dropDownValue.value,
    localDispatch,
    props.familyGroupDetail?.socios,
    familyMemberOptions,
  ])

  useEffect(() => {
    localDispatch({
      type: HolderScreenActions.SET_PROVINCIES,
      payload: props.provincies,
    })
  }, [localDispatch, props.provincies])

  useEffect(() => {
    localDispatch({
      type: HolderScreenActions.SET_DROPDOWN_VALUE,
      payload: familyMemberOptions[0],
    })
  }, [familyMemberOptions])

  const changesSuccess = useSelector(selectChangesSuccess)
  const saveDocumentationSuccess = useSelector(selectSaveDocumentationSuccess)

  const changesStatusSuccess = useSelector(selectChangesStatusGFSuccess)

  return (
    <S.HolderScreenContainer>
      <div
        onClick={props.onPressBack}
        style={{ textDecoration: 'unset', cursor: 'default' }}
      >
        <C.Row alignItems={'center'}>
          <S.IconContainer>
            <C.IconArrowBackIos
              centered={false}
              color={'resolutionBlue'}
              size={'xxs'}
            />
          </S.IconContainer>
          <C.Text size={'sm'} color={'resolutionBlue'} weight={'m'}>
            Volver al listado de grupos familiares
          </C.Text>
        </C.Row>
      </div>
      <S.HolderScreenContent>
        <S.FirstColumn>
          <C.Acordion
            title="Grupo Familiar"
            open
            tag={[
              <S.QuantityFamily>
                {familyMemberOptions.length} integrantes
              </S.QuantityFamily>,
            ]}
          >
            <S.GroupData>
              <C.Text size={'sm'}>
                Seleccioná el integrante del grupo que necesites visualizar
              </C.Text>
              <C.TableDropdown
                options={familyMemberOptions}
                value={state.dropDownValue}
                onChange={v =>
                  localDispatch({
                    type: HolderScreenActions.SET_DROPDOWN_VALUE,
                    payload: v,
                  })
                }
                id={'familyMemberDropDown'}
                name={'familyMemberDropDown'}
                adaptable
                bordered
                companyDropdown
                isPadEmpresas
              />
            </S.GroupData>
            <S.GroupData>
              <C.Text size={'md'} weight="md">
                Parentesco
              </C.Text>
              <C.LineHR color="greyC6" />
              <C.Text size="sm" weight="md" color="chathamsBlue">
                {state.familyMemberMappedKeyValueData?.familyMemberType}
              </C.Text>
            </S.GroupData>
            <S.GroupData>
              <C.Text size={'md'} weight="md">
                Tipo de afiliado
              </C.Text>
              <C.LineHR color="greyC6" />
              <C.Text size="sm" weight="md" color="chathamsBlue">
                {findKeyByValueTypeAfiliation(
                  TYPE_AFILIATION,
                  familyDetailData?.tipoAfiliacion || 1
                )}
              </C.Text>
            </S.GroupData>
            <S.GroupData>
              <C.Text size="md" weight="md" margin="2px 0">
                Datos personales
              </C.Text>
              <C.LineHR color="greyC6" />
              {state.familyMemberMappedKeyValueData?.personalData.map(
                (e: any) => (
                  <C.KeyValue
                    key={e.label}
                    label={e.label}
                    value={e.value}
                    noCamelCase
                  />
                )
              )}
            </S.GroupData>
            <S.GroupData>
              <C.Text size="md" weight="md">
                Domicilio
              </C.Text>
              <C.LineHR color="greyC6" />
              <C.Row gap="20px">
                {addressData?.slice(0, 4).map((e: any) => (
                  <C.KeyValue key={e.label} label={e.label} value={e.value} />
                ))}
              </C.Row>
              <C.Row gap="20px">
                {addressData?.slice(4).map((e: any) => (
                  <C.KeyValue key={e.label} label={e.label} value={e.value} />
                ))}
              </C.Row>
            </S.GroupData>
            <S.GroupData>
              <C.Text size="md" weight="md">
                Cobertura
              </C.Text>
              <C.LineHR color="greyC6" />
              {coverageData?.map((e: any) => (
                <C.KeyValue
                  key={e.label}
                  label={e.label}
                  value={e.value}
                  noCamelCase
                />
              ))}
            </S.GroupData>
            <S.GroupData>
              <C.Text size="md" weight="md">
                Integrantes del grupo
              </C.Text>
              <C.LineHR color="greyC6" />
              <C.KeyValue
                label={'¿El grupo incluye a su pareja?'}
                value={props.familyGroupDetail?.incluyePareja}
                noCamelCase
              />
              <C.KeyValue
                label={'Cantidad de hijos'}
                value={props.familyGroupDetail?.cantidadHijos}
                noCamelCase
              />
            </S.GroupData>
          </C.Acordion>
          <C.Acordion
            title="Acuerdo"
            open={true}
            headerButton={[
              <C.Permission
                permissionRequired={'addFamilyGroupObservations'}
                displayInauthorizedComponent={false}
              >
                <C.Button
                  onClick={() => {
                    setModalContract(!modal)
                  }}
                  outline
                  centered
                  disabled={props.familyGroupDetail?.tipoAfiliacion === 3}
                >
                  Modificar acuerdo
                </C.Button>
              </C.Permission>,
            ]}
          >
            <S.AcordionContent>
              <C.KeyValue
                label={'Acuerdo'}
                value={
                  props.familyGroupDetail?.tipoAfiliacion === 3
                    ? 'Sin acuerdo'
                    : props.familyGroupDetail?.acuerdoOoss
                }
                noCamelCase
              />
            </S.AcordionContent>
          </C.Acordion>
          <C.Acordion
            title="Observaciones"
            open={true}
            headerButton={[
              <C.Permission
                permissionRequired={'addFamilyGroupObservations'}
                displayInauthorizedComponent={false}
              >
                <C.Button
                  onClick={() => {
                    setModalObservation(!modal)
                  }}
                  outline
                  centered
                >
                  Agregar nueva
                </C.Button>
              </C.Permission>,
            ]}
          >
            <S.AcordionContent>
              <Observations
                data={props.familyGroupDetail?.observaciones}
                showObservations={showObservations}
                handleBtn={seeMoreObservations}
              />
            </S.AcordionContent>
          </C.Acordion>
          <C.Acordion
            title="Documentación"
            open={true}
            headerButton={[
              <C.Permission
                permissionRequired={'addFamilyGroupObservations'}
                displayInauthorizedComponent={false}
              >
                <C.Button
                  onClick={() => {
                    setModalDocumentation(!modal)
                  }}
                  outline
                  centered
                >
                  Agregar documentación
                </C.Button>
              </C.Permission>,
            ]}
          >
            <S.AcordionContent>
              {props.familyGroupDetail?.documentaciones.map((e: any) => (
                <>
                  <S.LinkDocumentation>
                    <IconPaperClip centered={false} size={'xs'} />
                    <S.NameDocumentation
                      onClick={() => {
                        companyPADService.downloadDocumentation(
                          e.urlArchivo,
                          token,
                          e.nombre
                        )
                      }}
                    >
                      {e.nombre}
                    </S.NameDocumentation>
                  </S.LinkDocumentation>
                  <C.LineHR color="greyC6" />
                </>
              ))}
            </S.AcordionContent>
          </C.Acordion>
          <C.Acordion title="Declaraciones juradas de salud" open>
            <S.AcordionContent>
              <C.Text>
                Seleccioná el integrante del grupo que necesites visualizar
              </C.Text>
              <C.Text
                textAlign="center"
                color="chathamsBlue"
                weight="md"
              >{`${state.familyMember?.apellido} ${state.familyMember?.nombre}`}</C.Text>
              {state.familyMember?.djs?.respuestas.map(r => {
                return (
                  <>
                    <S.DjsRows>
                      <C.Text color="chathamsBlue" size="sm">
                        {r.pregunta}{' '}
                      </C.Text>
                      <C.Text weight="md">{r.resultado ? 'SI' : 'NO'}</C.Text>
                    </S.DjsRows>
                    <hr />
                  </>
                )
              })}
              {state.familyMember?.djs && (
                <>
                  <S.DjsRows>
                    <C.Text color="chathamsBlue" size="sm">
                      {' '}
                      Altura (en cm){' '}
                    </C.Text>
                    <C.Text weight="md">
                      {state.familyMember?.djs?.altura}
                    </C.Text>
                  </S.DjsRows>
                  <hr />
                  <S.DjsRows>
                    <C.Text color="chathamsBlue" size="sm">
                      {' '}
                      Peso (en kg){' '}
                    </C.Text>
                    <C.Text weight="md">{state.familyMember?.djs?.peso}</C.Text>
                  </S.DjsRows>
                </>
              )}
            </S.AcordionContent>
          </C.Acordion>
        </S.FirstColumn>
        <S.SecondColumn>
          <History props={props} page="holder" />
          <C.Acordion
            title="Estado"
            open
            headerButton={[
              <C.Permission
                permissionRequired={'modifyFamilyGroupState'}
                displayInauthorizedComponent={false}
              >
                <C.Button
                  onClick={() => {
                    setModal(!modal)
                  }}
                  outline
                  centered
                >
                  Modificar estado
                </C.Button>
              </C.Permission>,
            ]}
          >
            <C.KeyValue
              label="Estado"
              value={props.familyGroupDetail?.estado}
            />
            <C.KeyValue
              label="Motivo"
              value={props.familyGroupDetail?.motivo}
            />
          </C.Acordion>
        </S.SecondColumn>
      </S.HolderScreenContent>
      <StatesModal
        onSubmit={handleSubmit}
        show={modal}
        setShow={setModal}
        idTipoEstado={familyMemberState}
        confirmationStatusApproved={confirmationStatusApproved}
      />
      <DocumentationModal
        onSubmit={handleSubmitDocumentation}
        show={modalDocumentation}
        setShow={setModalDocumentation}
        idTipoEstado={familyMemberState}
        idTitular={props.familyGroupDetail?.socios[0]?.dni}
      />
      <ObservationModal
        onSubmit={handleSubmitEvent}
        show={modalObservation}
        setShow={setModalObservation}
        idTipoEstado={familyMemberState}
      />
      <ContractTypeModal
        show={modalContract}
        setShow={setModalContract}
        codObraSocial={familyDetailData?.obraSocial?.codigo}
      />
      {changesStatusSuccess && (
        <S.MessageContainer>
          <SavedChangesMsgPAD
            variant="success"
            showMsg={!!changesStatusSuccess}
          >
            Estado guardado correctamente
          </SavedChangesMsgPAD>
        </S.MessageContainer>
      )}

      {changesSuccess ? (
        <S.MessageContainer>
          <SavedChangesMsgPAD variant="success" showMsg={changesSuccess}>
            Cambios guardados
          </SavedChangesMsgPAD>
        </S.MessageContainer>
      ) : (
        ''
      )}
      {saveDocumentationSuccess ? (
        <S.MessageContainer>
          <SavedChangesMsgPAD
            variant="success"
            showMsg={saveDocumentationSuccess}
          >
            Documentación guardada
          </SavedChangesMsgPAD>
        </S.MessageContainer>
      ) : (
        ''
      )}
    </S.HolderScreenContainer>
  )
}

export default HolderDataCompanyScreen
