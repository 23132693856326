import { State } from 'domain/entities'

import { IStateEntityGateway } from 'domain/entityGateways/IStateEntityGateway'

import {
  TGetStates,
  TGetAvailableStateParams,
  TPostStateDocumentsParam,
  TPostUpdateStatePymeParam,
} from 'domain/requestModel'

import { getAllStatesByStateTypeId } from 'domain/responseModel'

export class UseCaseState implements IStateEntityGateway {
  private entityGateway: IStateEntityGateway

  constructor(entityGateway: IStateEntityGateway) {
    this.entityGateway = entityGateway
  }

  public async getStates(params: TGetStates): Promise<State[]> {
    const response = await this.entityGateway.getStates(params)

    return getAllStatesByStateTypeId(response)
  }

  public async getAvailableStates(params: TGetAvailableStateParams) {
    const requestParams: TGetAvailableStateParams = {
      idEstado: params.idEstado,
      ...(params.tipoPlan === 5 && { idTipoEstado: 5 }),
    }

    const result = await this.entityGateway.getAvailableStates(requestParams)

    return result
  }

  public async getStateBackofficeNormalize() {
    const result = await this.entityGateway.getStateBackofficeNormalize()

    return result
  }

  public async getAvailableStatesBO(param: TGetAvailableStateParams) {
    const result = await this.entityGateway.getAvailableStatesBO(param)

    return result
  }

  public async getAvailablePymeStates(
    params: TGetAvailableStateParams
  ): Promise<State[]> {
    const resultPyme = await this.entityGateway.getAvailablePymeStates(params)
    return resultPyme
  }

  public async updateDocumentationStates(params: TPostStateDocumentsParam) {
    const result = await this.entityGateway.updateDocumentationStates(params)
    return result
  }

  public async updateStatusPyme(params: TPostUpdateStatePymeParam) {
    const result = await this.entityGateway.updateStatusPyme(params)
    return result
  }

  public async getDocumentationState(
    params: TGetAvailableStateParams
  ): Promise<State[]> {
    const resultDoc = await this.entityGateway.getDocumentationState(params)
    return resultDoc
  }
}
export default {
  UseCaseState,
}
