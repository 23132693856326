import { Afilliation, AfilliationAssociate } from 'domain/entities'
import { ISelectFormat } from 'app/components'

import { usersRelated } from 'app/constants'
import {
  getFormatedDate,
  DayMonthYearWithLeadingZerosSlashedWithHr,
  numberWithSeparators,
} from 'helpers'

export type TDetailModelParam = {
  association: Afilliation
  associate: AfilliationAssociate[]
  backofficeStates: ISelectFormat[]
  associateId: number
}

export type TDetailModel = {
  dni: string
  years: number | string
  gender: string
  phone: string
  weight: string
  height: string
  imc: string
  planName: string
  effectiveDate: string
  afilliationDate: string
  desregulationState: string
}

export const detailViewModel = (
  raw: TDetailModelParam
): TDetailModel | null => {
  const associate: AfilliationAssociate | undefined = raw.associate.find(
    (a: AfilliationAssociate) => a.id === raw.associateId
  )

  if (!associate) return null

  return {
    dni: associate.dni,
    years: associate.edad === 0 ? 'recién nacido' : associate.edad,
    gender: usersRelated.sexStrings[associate.sexo],
    phone: associate.telefonoMovil,
    weight: `${associate.peso} kg`,
    height: Number(associate.altura)
      ? `${numberWithSeparators({ number: associate.altura })} cm`
      : `${associate.altura} cm`,
    imc: numberWithSeparators({ number: associate.djs.imcValor }),
    planName: raw.association.nombrePlan,
    effectiveDate: getFormatedDate(
      raw.association.fechaVigencia,
      DayMonthYearWithLeadingZerosSlashedWithHr
    ),
    afilliationDate: getFormatedDate(
      raw.association.fechaCotizacion,
      DayMonthYearWithLeadingZerosSlashedWithHr
    ),
    desregulationState:
      raw.backofficeStates.find(
        (b: ISelectFormat) => b.value === raw.association.estadoBackOffice
      )?.label || 'No se ha encontrado el estado',
  }
}
