import { pagination } from './types'

import { ISelectFormat } from 'app/components'

export type getCompaniesRequestParams = {
  fechaIngreso?: string
  listaEstados: ISelectFormat
  cantidad: number
  pagina: number
}

export type TGetCompaniesRequestModel = {
  fechaIngreso?: string
  listaEstados?: number
  cantidad?: number
  pagina?: number
  orderBy?: string
  filtro?: string
  orderDirection?: string
}

export type getCompaniesRequestModel =
  | (pagination & TGetCompaniesRequestModel)
  | undefined

export const makeCompanyRequestModel = (
  raw: getCompaniesRequestParams
): getCompaniesRequestModel => {
  return {
    pagina: raw.pagina,
    cantidad: raw.cantidad,
    fechaIngreso: raw.fechaIngreso,
    listaEstados:
      raw.listaEstados?.value !== -1 ? raw.listaEstados?.value : null,
  }
}

export type getCompanyRequestParam = {
  companyId: string
}

export type getCompanyStateParam = {
  idTipoEstado: number
  subTipo?: number
}
export type getCompanyStateDisponiblesParam = {
  idEstado: number
  subTipo?: number
  idTipoEstado?: number
}

export type getFamilyGroupDetailParam = {
  guid: string
}

export type changeStateFGParams = {
  guid: string
  observacion: string
  eventoAfiliacion: number
}

export type changeStatusGFParams = {
  guid: string
  observacion: string
  eventoAfiliacion: number
}
export type fileParams = {
  guid: string
  dniTitular: string
  archivo: File
  tipoArchivo: string
}

export type createCompanyParam = {
  id: number
}
